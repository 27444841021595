import React from "react"
import Img from "gatsby-image"

import styles from "./hero.module.scss"

const Hero = props => (
  <div className={styles.hero}>
    <div className={styles.imgHolder}>
      <Img
        fluid={props.image.childImageSharp.fluid}
        imgStyle={{
          objectFit: "cover",
          objectPosition: "50% 50%",
        }}
      />
    </div>
    <h1>{props.title}</h1>
  </div>
)

export default Hero
